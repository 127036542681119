import {
  Await,
  defer,
  Form,
  Params,
  redirect,
  useLoaderData,
  useParams,
  json,
} from "react-router-dom";
import { adventApiClient, getUser } from "../../services/session";
import {
  GetRumCommand,
  GetRumOutput,
  GetQuizCommand,
  GetQuizOutput,
  PostQuizCommand,
} from "@tragas/advent-api-sdk";
import set from "lodash/set";
import React from "react";
import Markdown from "markdown-to-jsx";

const badUsers = ["jeromedauge", "sarah.goffaux.13", "elodie", "doriandethise"];

export async function getQuestion({ params }: { params: Params<string> }) {
  // const day = new Date().getDate();
  const user = getUser();

  try {
    const dayParam = parseInt(params.day!, 10);

    if (dayParam > 25) {
      return redirect("..");
    }

    if (
      dayParam > 24 &&
      badUsers.find((u) => user?.profile?.email?.includes(u))
    ) {
      return json({});
    } else if (dayParam > 24) {
      window.location.replace("https://www.youtube.com/watch?v=dQw4w9WgXcQ");
    }

    const getRumCommand = new GetRumCommand({ day_id: dayParam - 1 });
    const getQuizCommand = new GetQuizCommand({ day_id: dayParam - 1 });

    return defer({
      quiz: adventApiClient.send(getQuizCommand),
      rum: adventApiClient.send(getRumCommand),
    });
  } catch (e) {
    return redirect("..");
  }
}

export async function answerQuestion({
  params,
  request,
}: {
  params: Params<string>;
  request: Request;
}) {
  const form = await request.formData();

  const body = {
    quiz_answers: [],
  };

  // @ts-expect-error
  for (const [key, value] of form.entries()) {
    set(
      body,
      key,
      key.endsWith("question_id")
        ? Number(value)
        : typeof value === "string"
        ? [value]
        : value
    );
  }

  const postQuizCommand = new PostQuizCommand({
    day_id: parseInt(params.day!, 10) - 1,
    quiz_answers: body.quiz_answers,
  });
  await adventApiClient.send(postQuizCommand);
  window.location.reload();
}

type Data = {
  quiz: GetQuizOutput;
  rum?: GetRumOutput;
};

export function QuestionPage() {
  const params = useParams();
  const user = getUser();
  const data = useLoaderData() as Data;

  if (
    parseInt(params.day!) > 24 &&
    badUsers.find((u) => user?.profile?.email?.includes(u))
  ) {
    return (
      <div className="w-full mx-auto px-4 md:px-8 max-w-5xl">
        <h1 className="my-4 md:my-16 text-2xl font-extrabold tracking-tight leading-none text-gray-900 md:text-3xl lg:text-6xl">
          Bon anniversaire {user?.profile.given_name} ❤️
        </h1>
      </div>
    );
  }

  return (
    <div className="w-full mx-auto px-4 md:px-8 max-w-5xl" key={params.day}>
      <React.Suspense fallback="">
        <Await resolve={data.rum}>
          {(rum: GetRumOutput) => (
            <>
              {rum.output && (
                <>
                  <header>
                    <h1 className="my-4 md:my-16 text-lg font-extrabold tracking-tight leading-none text-gray-900 md:text-xl lg:text-3xl">
                      {rum.output.rum_name}
                    </h1>
                  </header>
                  <section className="flex flex-col xl:flex-row mt-4">
                    <div className="flex flex-col grow">
                      {[
                        { key: "Marque", value: rum.output.rum_brand },
                        { key: "Type de rhum", value: rum.output.rum_type },
                        {
                          key: "Type de distillation",
                          value: rum.output.distillation_type,
                        },
                        { key: "Provenance", value: rum.output.rum_place },
                        {
                          key: "Où acheter",
                          value: rum.output.where_to_buy,
                          type: "url",
                        },
                      ].map(({ key, value, type }, idx) => (
                        <React.Fragment key={key}>
                          <div className="flex justify-between items-center">
                            <span className="uppercase font-semibold text-xs md:text-lg text-gray-900 mr-4">
                              {key}
                            </span>
                            <span className="text-xs md:text-base text-gray-900">
                              {type === "url" ? (
                                <a
                                  className="underline font-medium hover:no-underline"
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={value}
                                >
                                  {value}
                                </a>
                              ) : (
                                value
                              )}
                            </span>
                          </div>
                          {idx < 4 && <hr className="my-2 md:my-4" />}
                        </React.Fragment>
                      ))}
                      {rum.output.full_text?.map((text, idx) => (
                        <div
                          key={idx}
                          className={`xl:hidden ${idx === 0 ? "mt-4" : "0"}`}
                        >
                          <p>
                            {text.title && (
                              <h3 className="uppercase mb-3 font-semibold text-xs md:text-lg text-gray-900 mr-4">
                                {text.title}
                              </h3>
                            )}
                            <span className="text-xs md:text-base text-gray-900">
                              <Markdown
                                options={{
                                  overrides: {
                                    a: (p: any) => (
                                      <a
                                        {...p}
                                        className="underline font-medium hover:no-underline"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      />
                                    ),
                                  },
                                }}
                              >
                                {text.text ?? ""}
                              </Markdown>
                            </span>
                          </p>
                        </div>
                      ))}
                    </div>
                    <div className="xl:pl-16 mt-8 xl:mt-0 xl:mb-8">
                      <img
                        src={rum.output.image}
                        alt="Rum"
                        height={320}
                        width={320}
                      />
                    </div>
                  </section>
                  {rum.output.full_text?.map((text, idx) => (
                    <div key={idx} className="hidden xl:block">
                      <p>
                        {text.title && (
                          <h3 className="uppercase mb-3 font-semibold text-xs md:text-lg text-gray-900 mr-4">
                            {text.title}
                          </h3>
                        )}
                        <span className="text-xs md:text-base text-gray-900">
                          <Markdown
                            options={{
                              overrides: {
                                a: (p: any) => (
                                  <a
                                    {...p}
                                    className="underline font-medium hover:no-underline"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  />
                                ),
                              },
                            }}
                          >
                            {text.text ?? ""}
                          </Markdown>
                        </span>
                      </p>
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </Await>
      </React.Suspense>
      <React.Suspense fallback="">
        <Await resolve={data.quiz}>
          {(quiz: GetQuizOutput) => (
            <>
              {quiz.output?.quiz && (
                <header>
                  <h1 className="my-4 md:my-16 text-lg font-extrabold tracking-tight leading-none text-gray-900 md:text-xl lg:text-3xl">
                    Question(s)
                  </h1>
                </header>
              )}
              {quiz.output?.quiz && (
                <Form method="post">
                  {quiz.output?.quiz.map((q, idx) => (
                    <div key={q.question_id}>
                      <input
                        type="hidden"
                        name={`quiz_answers[${idx}].question_id`}
                        value={q.question_id}
                      />
                      <div key={q.question_id} className="flex my-4 mx-4">
                        {q.question_id}. {q.question}
                      </div>
                      {q.choices?.map((choice, i) => (
                        <div
                          key={`${q.question_id}-${choice}`}
                          className="flex items-center ml-8 mt-4"
                        >
                          <input
                            id={`checked-${q.choice_type}-${choice}`}
                            type={q.choice_type}
                            name={
                              q.choice_type === "radio"
                                ? `quiz_answers[${idx}].answers`
                                : `quiz_answers[${idx}].answers[${i}]`
                            }
                            value={choice}
                            className="w-4 h-4 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
                            required
                          />
                          <label
                            htmlFor={`checked-${q.choice_type}-${choice}`}
                            className="ml-2 text-sm font-medium text-gray-900"
                          >
                            {choice}
                          </label>
                        </div>
                      ))}
                    </div>
                  ))}
                  <div className="flex justify-center mt-8">
                    <button className="text-white bg-blue-400 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-400 focus:outline-none dark:focus:ring-blue-800">
                      Répondre
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        const postQuizCommand = new PostQuizCommand({
                          day_id: parseInt(params.day!, 10) - 1,
                          quiz_answers: [],
                        });
                        return adventApiClient
                          .send(postQuizCommand)
                          .then(() => {
                            window.location.reload();
                          });
                      }}
                      className="text-white bg-gray-400 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-600 dark:hover:bg-gray-400 focus:outline-none dark:focus:ring-gray-800"
                    >
                      Passer
                    </button>
                  </div>
                </Form>
              )}
              {quiz.output?.results && (
                <div className="text-xs md:text-base">
                  <h2 className="mt-8 text-lg font-bold tracking-tight leading-none text-gray-900 md:text-xl lg:text-2xl">
                    Score: {quiz.output.results.day_score}
                  </h2>
                  <h3 className="my-4 text-base font-bold tracking-tight leading-none text-gray-900 md:text-lg lg:text-xl">
                    Question(s)
                  </h3>
                  <ul className="ml-4 list-disc">
                    {quiz.output.results.results?.map((result, idx) => (
                      <li
                        className={idx !== 0 ? "mt-4" : "0"}
                        key={result.question}
                      >
                        <div>{result.question}</div>
                        <div className="mt-4">
                          Réponse donnée: {result.user_answers}
                        </div>
                        <div className="mt-4">
                          Réponse valide: {result.valid_answers}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          )}
        </Await>
      </React.Suspense>
    </div>
  );
}
