import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";

export function QuizLayout() {
  const param = useParams();
  const navigate = useNavigate();
  const day = 25; // Math.min(new Date().getDate(), 25);

  return (
    <div className="flex flex-col w-full md:flex-row">
      <aside
        className="hidden w-16 md:w-48 xl:w-64 mt-4 ml-4 md:block"
        aria-label="Sidebar"
      >
        <div className="overflow-y-auto py-4 px-3 rounded bg-gray-700">
          <ul className="space-y-2">
            {new Array(day).fill(null).map((_, i) => (
              <NavLink
                className={({ isActive }) => {
                  const color = isActive ? "text-blue-400" : "text-white";
                  return `flex items-center p-2 text-base font-normal rounded-lg ${color} hover:bg-gray-100 hover:bg-gray-700`;
                }}
                to={String(i + 1)}
                key={String(i + 1)}
              >
                <li>Jour {i + 1}</li>
              </NavLink>
            ))}
          </ul>
        </div>
      </aside>
      <div className="block md:hidden w-full p-4 flex flex-col">
        <label
          htmlFor="days"
          className="ml-2 text-sm font-medium text-gray-900"
        >
          Choisissez un jour
        </label>
        <select
          id="days"
          className="p-2 mt-2 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
          onChange={(e) => {
            navigate(e.currentTarget.value);
          }}
          value={param.day}
        >
          {new Array(day).fill(null).map((_, i) => (
            <option key={i} value={String(i + 1)}>
              Jour {i + 1}
            </option>
          ))}
        </select>
      </div>
      <Outlet />
    </div>
  );
}
