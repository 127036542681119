import type { User } from "oidc-client-ts";
import { AuthProviderProps } from "react-oidc-context";

export const oidcConfig: AuthProviderProps = {
  authority: "https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_lg9bLHG0w/",
  client_id: "59edklt3pb55626p90gla2v94o",
  redirect_uri: "https://advent.tkfanta.com",
  onSigninCallback: (_user: User | void): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
};
