function Arrow() {
  return (
    <svg
      className="fill-current opacity-75 w-4 h-4 -mr-1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
    </svg>
  );
}

export function Root() {
  return (
    <div className="max-w-2xl mx-auto px-4 text-justify text-gray-900">
      <h1 className="my-8 text-2xl font-extrabold tracking-tight leading-none text-gray-900 lg:text-4xl">
        Advent of Rum
      </h1>
      <h2 className="my-8 text-lg font-bold text-gray-900 md:text-xl lg:text-2xl">
        À la demande de plusieurs d'entre vous, le jeu-concours s'étend jusqu'au
        15 janvier. Dépêchez-vous de terminer !
      </h2>
      <section>
        <p className="text-xs md:text-base">
          Merci d'avoir acheté l'advent of rum !
        </p>
        <p className="text-xs md:text-base mt-2">
          J'y ai passé beaucoup de temps et j'espère que l'expérience vous
          plaira.
        </p>
        <p className="text-xs md:text-base mt-2">
          Les calendriers de l'avent étant basés sur une formule bien connue,
          j'ai tenté au maximum de créer la surprise, et presque chaque
          échantillon devrait vous arracher un haussement de sourcil intéressé.
          En tout cas, c'est l'effet que je recherche !
        </p>
        <details className="question border-b border-grey-lighter py-6">
          <summary className="flex items-center text-lg font-bold tracking-tight leading-none text-gray-900 md:text-xl lg:text-2xl">
            Dégustation
            <span className="ml-auto">
              <Arrow />
            </span>
          </summary>
          <div className="mt-8 text-xs md:text-base">
            Déguster un rhum n'a rien de compliqué mais si vous voulez maximiser
            le plaisir, voici quelques conseils, sans prétention aucune :
            <ol className="list-decimal list-inside">
              {[
                "Prenez votre temps. Posez-vous, installez-vous confortablement, avec ou sans compagnie (les échantillons font 2 cl, et 1 cl suffit pour goûter).",
                "Commencez par sentir le rhum. Passez-le d'une narine à l'autre (chaque narine va apporter quelque chose de différent). Idéalement, versez le rhum dans un verre tulipe ou équivalent ! Pro tip : un rhum ne se sent pas comme un vin, en l'agitant plus ou moins vigoureusement. Si vous le faites, la première chose qui se dégagera sera l'éthanol, ce qui aura pour effet de vous brûler le nez. Préférez faire glisser le rhum doucement sur les parois du verre, en le maintenant quasiment à l'horizontale.",
                "Laissez-le aérer ! Certains rhums ne se révèlent qu'après quelques minutes d'oxydation. Je vous mettrai une note pour chaque rhum où cette étape sera particulièrement intéressante.",
                "Lorsque vous goûtez, usez et abusez de la rétro-olfaction. Le principe est de faire entrer de l'air par la bouche et de le faire ressortir par le nez. L'essentiel des arômes est capté de cette manière.",
                "Une fois la dégustation finie, n'hésitez pas à revenir humer votre verre quelques minutes plus tard. On retrouvera encore d'autres parfums !",
              ].map((text, idx) => (
                <li key={idx} className="mt-4">
                  {text}
                </li>
              ))}
            </ol>
          </div>
        </details>
        <details className="question border-b border-grey-lighter py-6">
          <summary className="flex items-center text-lg font-bold tracking-tight leading-none text-gray-900 md:text-xl lg:text-2xl">
            Fabrication
            <span className="ml-auto">
              <Arrow />
            </span>
          </summary>
          <p className="mt-8 text-xs md:text-base">
            Le rhum est incroyablement versatile. Il peut être complexe, festif,
            doux, explosif, préparé en cocktails, arrangé, épicé, ... Cette
            polyvalence vient des nombreux facteurs intervenant dans sa
            fabrication, et des étapes postérieures que l'on peut pratiquer une
            fois un produit “de base” entre les mains : vieillissement,
            filtration, réduction, arrangement, mélange avec d'autres éléments,
            ...
            <br />
            Je me contenterai ici de résumer dans les grandes lignes les
            méthodes de fabrication des rhums les plus traditionnels, et
            j'expliquerai dans les fiches de chaque rhum lorsque l'on s'éloigne
            de ce “canon”.
          </p>
          <p className="text-xs md:text-base mt-4">
            Le rhum est à la base une manière de valoriser des déchêts ! Lorsque
            l'industrie sucrière s'est développée dans les Caraïbes avec
            l'arrivée de la canne à sucre, les producteurs se sont retrouvés
            avec des quantités énormes de mélasse sur les bras. Ils ont ajouté
            un peu d'eau à cette mélasse, l'ont laissée dans un coin quelques
            jours, et se sont retrouvés avec un jus légèrement alcoolisé par la
            fermentation naturelle. Après distillation, ce jus a donné les
            premiers rhums (relativement ignobles, ils étaient destinés aux
            esclaves travaillant dans les champs de canne et de tabac et aux
            marins). <br />
            Aujourd'hui, les processus ont bien évolué, mais le principe reste
            le même. On part d'un matériau de base issu de la canne à sucre, on
            le fait fermenter, puis on le distille pour concentrer l'alcool.
          </p>
          <p className="text-xs md:text-base mt-4">
            On fermente à l'heure actuelle deux ingrédients de base : la mélasse
            comme citée précédemment, mais aussi le jus de canne directement.
            Les rhums pur jus de canne auront souvent un côté plus végétal,
            terreux, que les rhums de mélasse qui eux seront souvent plus doux.
            Ce ne sont toutefois pas des règles absolues ! Vous voulez plus
            d'informations sur la fermentation ?{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              className="underline font-medium hover:no-underline"
              href="https://www.rhumattitude.com/blog/letape-cruciale-de-la-fermentation/"
            >
              C'est par ici !
            </a>
          </p>
          <p className="text-xs md:text-base mt-4">
            Une fois qu'on a notre jus fermenté, il est temps d'en concentrer
            les arômes et de faire monter son degré d'alcool. On utilise pour
            cela deux types de distillation : en colonne ou en alambic à repasse
            (pot-still). La colonne donnera souvent un rhum plus léger en
            arômes, à l'inverse de l'alambic qui conservera mieux les composants
            organoleptiques. Vous avez soif de connaissances sur la distillation
            ?{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              className="underline font-medium hover:no-underline"
              href="https://www.rhumattitude.com/blog/la-distillation-la-ou-nait-le-rhum/"
            >
              Abreuvez-vous ici.
            </a>
          </p>
          <p className="text-xs md:text-base mt-4">
            Lorsque la distillation est terminée, le rhum est né ! On peut
            l'embouteiller tel quel (brut de distillation), le réduire lentement
            avec de l'eau de source pour obtenir un rhum moins alcoolisé avant
            de le mettre en bouteille, mais généralement, les producteurs
            voudront réaliser un vieillissement en fût. Il aura pour effet de
            complexifier l'eau-de-vie, tout en calmant les ardeurs d'un rhum
            jeune et explosif. Une fois vieilli, on peut l'embouteiller
            directement à la sortie du fût (brut de fût/cask strength, et single
            cask s'il vient d'un seul et unique fût), le mélanger avec d'autres
            rhums (blend), le réduire, le filtrer, lui rajouter des arômes
            artificiels (spiced), ... Petit lexique en prime, c'est gratos, ça
            me fait plaisir. Du rab' d'infos sur le veillissement ?{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              className="underline font-medium hover:no-underline"
              href="https://www.rhumattitude.com/blog/lart-du-vieillissement-des-rhums/"
            >
              Mais bien entendu.
            </a>
          </p>
          <p className="text-xs md:text-base mt-4">
            Alors, c'est dense hein ? Oui. Et on aime ça.
          </p>
        </details>
        <details className="question border-b border-grey-lighter py-6">
          <summary className="flex items-center text-lg font-bold tracking-tight leading-none text-gray-900 md:text-xl lg:text-2xl">
            Types de rhums
            <span className="ml-auto">
              <Arrow />
            </span>
          </summary>
          <p className="mt-8 text-xs md:text-base">
            On peut distinguer au moins trois “familles” de rhums : le “rhum”
            français, le “rum” anglais et le “ron” espagnol. Issus
            respectivement des traditions des colonies françaises, anglaises et
            espagnoles (un ron n'est pas exclusivement fabriqué en Espagne, non
            non).
          </p>
          <p className="text-xs md:text-base mt-4">
            Le rhum français ou rhum agricole est un rhum pur jus de canne, et
            on la sent bien présente tout au long de la dégustation. Il peut
            être embouteillé sans vieillissement, auquel cas il sera plus frais,
            plus herbacé, plus tonique, ou au contraire subir une mise en fût
            plus ou moins prolongée, qui aura tendance à l'assécher et lui
            donner des notes... Boisées (incroyable), ou épicées.
          </p>
          <p className="text-xs md:text-base mt-4">
            Le rhum de tradition anglaise sera quant à lui souvent à base de
            mélasses, plus lourd en arômes, assez gras et quasiment
            exclusivement vieilli, sauf pour ce qui est des rhums en provenance
            de Jamaïque (on en reparlera, vous inquiétez pas).
          </p>
          <p className="text-xs md:text-base mt-4">
            Le rhum léger ou rhum de tradition espagnole est un rhum la plupart
            du temps fermenté assez peu de temps, et distillé en colonnes. Cela
            donne un rhum relativement neutre, que l'on fera presque d'office
            vieillir au moins quelques années. Si cela dure moins de trois ans,
            on destinera plutôt ces rhums à la création de cocktails, tandis que
            les plus vieux, à la différence des rhums agricoles, seront plutôt
            doux, vanillés, caramélisés.
          </p>
          <p className="text-xs md:text-base mt-4">
            Un petit article détaillant plus en profondeur les deux précédentes
            sections ?{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              className="underline font-medium hover:no-underline"
              href="https://www.rhumattitude.com/blog/fabrication-du-rhum/"
            >
              Suffit de demander.
            </a>
          </p>
        </details>
        <details className="question border-b border-grey-lighter py-6">
          <summary className="flex items-center text-lg font-bold tracking-tight leading-none text-gray-900 md:text-xl lg:text-2xl">
            Game of Rums
            <span className="ml-auto">
              <Arrow />
            </span>
          </summary>
          <p className="mt-8 text-xs md:text-base">
            Vous l'avez peut-être remarqué avec les sections précédentes, j'aime
            bien donner un peu (?) de contexte. Dans cette optique, il était
            hors de question de vous laisser déguster ma sélection sans vous
            proposer un support "théorique". Pour chaque rhum, vous aurez donc
            via ce site une page d'explication avec des détails tels que sa
            provenance, son type, comment il a été distillé, ou encore où
            l'acheter. Mais comme il y avait une chance pour que j'arrive à
            finir ça sans me mettre la corde au cou la veille de la distribution
            des calendriers, j'ai jugé utile de rajouter un petit jeu. Libre à
            vous d'y participer ou non.
          </p>
          <p className="text-xs md:text-base mt-4">
            Voici le principe : chaque jour, vous pouvez choisir de répondre à
            quelques questions sur votre rhum, en dégustant d'abord à l'aveugle.
            Vous gagnez des points en fonction de vos réponses, et dès que vous
            avez répondu, vous avez accès à la fiche descriptive du rhum du
            jour. Je vous conseille de goûter la moitié de l'échantillon à
            l'aveugle, puis l'autre moitié avec les explications. Si vous ne
            voulez pas participer, il y aura sous chaque quiz un bouton pour
            passer cette étape et accéder directement à la fiche. Attention, il
            ne sera alors plus possible de gagner les points du jour ! Par
            contre, si vous ne passez pas, il sera possible de répondre aux
            questions plusieurs jours après le jour de dégustation prévu. Je
            clôturerai le classement final le 1er janvier, et le, la ou les
            gagnants recevront un set de verres de dégustation !
          </p>
          <p className="text-xs md:text-base mt-4">
            Ne vous dites pas que vous n'avez aucune chance parce que vous n'y
            connaissez rien au rhum. Avec les explications ci-dessus, un peu de
            pratique (vous en avez quand même 24 devant vous) et un peu de
            réflexion, vous devriez avoir toutes vos chances. Et puis dans le
            pire des cas, vous aurez appris quelques notions que vous pourrez
            ressortir pour briller en société !
          </p>
          <p className="text-xs md:text-base mt-4">Bonne chance à tous !</p>
        </details>
        <details className="question pt-6">
          <summary className="flex items-center text-lg font-bold tracking-tight leading-none text-gray-900 md:text-xl lg:text-2xl">
            Remerciements
            <span className="ml-auto">
              <Arrow />
            </span>
          </summary>
          <p className="mt-8 text-xs md:text-base">
            Ce petit projet n'aurait pas été possible sans l'aide de plusieurs
            personnes que je tiens à remercier. Alors merci à Matteo qui m'a
            aidé lors de la sélection des rhums et l'élaboration des boites, à
            Dorian pour son découpage précis et incisif malgré son poignet en
            vrac, à Simon pour le développement de la partie graphique du site
            sur lequel vous êtes, et à Emeline qui a accepté que je reporte
            *encore* la retouche de ses photos de grossesse alors que notre
            enfant a maintenant 7 mois. Elle a également donné un sérieux coup
            d'accélérateur à l'embouteillage (l'enfiolage ?) des rhums que vous
            dégustez ! Gros kissou à tout ce beau monde !
          </p>
        </details>
      </section>
    </div>
  );
}
