import React, { useEffect } from "react";
import { GetScoreCommand, GetScoreOutput } from "@tragas/advent-api-sdk";
import { hasAuthParams, useAuth } from "react-oidc-context";
import {
  Await,
  defer,
  json,
  NavLink,
  Outlet,
  useLoaderData,
  useNavigate,
  useParams,
} from "react-router-dom";
import { adventApiClient, getUser } from "../services/session";

export function getScore() {
  const user = getUser();

  if (!user || user.expired) {
    return json({});
  }

  const getScoreCommand = new GetScoreCommand({ id: "coucou" });
  return defer({ score: adventApiClient.send(getScoreCommand) });
}

export function RootLayout() {
  const data = useLoaderData() as { score: GetScoreOutput };
  const auth = useAuth();
  const navigate = useNavigate();

  // automatically sign-in
  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading
    ) {
      auth.signinRedirect();
    }
  }, [auth, navigate]);

  if (!auth.isAuthenticated) {
    return null;
  }

  const today = new Date().getDate();

  return (
    <>
      <nav className="border-gray-200 px-4 md:px-8 py-4 bg-gray-700">
        <div className="container flex flex-wrap justify-between items-center mx-auto">
          <NavLink to="/" className="flex items-center">
            <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
              🥃
            </span>
          </NavLink>
          <span className="text-xs text-white ml-4 md:text-base">
            {auth.user?.profile.email} -{" "}
            <React.Suspense fallback="">
              <Await resolve={data.score}>
                {(score: GetScoreOutput) => <>{score?.score}</>}
              </Await>
            </React.Suspense>
          </span>
          <div className="flex items-center">
            {[
              { label: "classement" },
              {
                label: "quiz",
                to: `quiz/${today > 24 ? "24" : today}`,
              },
            ].map(({ label, to }, idx) => (
              <NavLink
                to={`/${to ?? label}`}
                key={to ?? label}
                className={({ isActive }) => {
                  const color = isActive ? "text-blue-400" : "text-white";
                  const pr = idx === 0 ? "pr-4" : "";
                  const pl = idx === 0 ? "pl-2" : "";
                  return `py-2 ${pr} ${pl} text-xs md:text-base ${color} rounded bg-transparent`;
                }}
              >
                {label.replace(/^\w/, (c) => c.toUpperCase())}
              </NavLink>
            ))}
          </div>
        </div>
      </nav>
      <main className="mb-8">
        <Outlet />
      </main>
    </>
  );
}
