import { getRanking, RankingPage } from "./routes/Ranking";
import { getScore, RootLayout } from "./routes/RootLayout";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { QuizLayout } from "./routes/quiz/QuizLayout";
import {
  answerQuestion,
  getQuestion,
  QuestionPage,
} from "./routes/quiz/Question";
import { Root } from "./routes/Root";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    loader: getScore,
    errorElement: <Navigate to="/" />,
    children: [
      {
        index: true,
        element: <Root />,
      },
      {
        path: "classement",
        element: <RankingPage />,
        loader: getRanking,
      },
      {
        path: "quiz",
        element: <QuizLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="1" />,
          },
          {
            path: ":day",
            element: <QuestionPage />,
            loader: getQuestion,
            action: answerQuestion,
          },
        ],
      },
      {
        path: "*",
        element: <Navigate to="/" />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
