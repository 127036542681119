import { User } from "oidc-client-ts";
import invariant from "tiny-invariant";
import { AdventApiClient } from "@tragas/advent-api-sdk";
import { oidcConfig } from "../auth";

export function getUser(): User | null {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`
  );
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

function getToken() {
  const user = getUser();
  invariant(user, "session not found");
  invariant(!user.expired, "session expired");
  return user.id_token;
}

export const adventApiClient = new AdventApiClient({
  endpoint: "https://api.advent.tkfanta.com",
});

adventApiClient.middlewareStack.add(
  (next, _) => async (args: any) => {
    args.request.headers["Authorization"] = `Bearer ${getToken()}`;
    return await next(args);
  },
  {
    step: "build",
    name: "addJwtToken",
    tags: [],
  }
);
