import { ListScoresCommand, ListScoresOutput } from "@tragas/advent-api-sdk";
import React from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import { adventApiClient } from "../services/session";

export function getRanking() {
  const listScores = new ListScoresCommand({});
  return defer({ scores: adventApiClient.send(listScores) });
}

export function RankingPage() {
  const data = useLoaderData() as { scores: ListScoresOutput };

  return (
    <>
      <h1 className="my-8 text-2xl text-center font-extrabold tracking-tight leading-none text-gray-900 lg:text-4xl">
        Classement
      </h1>
      <div className="flex flex-col items-center">
        <table className="w-full max-w-xl text-sm text-left text-gray-700">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              {["#", "Nom", "Score", "À jour"].map((column) => (
                <th key={column} scope="col" className="py-2 px-3 md:py-3 md:px-6">
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <React.Suspense
              fallback={new Array(10).fill(null).map((_, idx) => (
                <tr
                  key={idx}
                  className={
                    idx % 2 === 0
                      ? "bg-white border-b animate-pulse"
                      : "bg-gray-300 border-b animate-pulse"
                  }
                >
                  <td className="py-2 px-3 md:py-4 md:px-6">{idx + 1}</td>
                  <td className="py-2 px-3 md:py-4 md:px-6">...</td>
                  <td className="py-2 px-3 md:py-4 md:px-6">...</td>
                  <td className="py-2 px-3 md:py-4 md:px-6">...</td>
                </tr>
              ))}
            >
              <Await resolve={data.scores}>
                {(scores: ListScoresOutput) => (
                  <>
                    {scores.output
                      ?.sort((a, b) =>
                        (a.current_score ?? 0) < (b.current_score ?? 0) ? 1 : -1
                      )
                      .map((user, idx) => (
                        <tr
                          key={user.user}
                          className={
                            idx % 2 === 0
                              ? "bg-white border-b"
                              : "bg-gray-100 border-b"
                          }
                        >
                          <td className="py-2 px-3 md:py-4 md:px-6">{idx + 1}</td>
                          <td className="py-2 px-3 md:py-4 md:px-6">{user.user}</td>
                          <td className="py-2 px-3 md:py-4 md:px-6">{user.current_score}</td>
                          <td className="py-2 px-3 md:py-4 md:px-6">
                            {user.up_to_date ? "✅" : "❌"}
                          </td>
                        </tr>
                      ))}
                  </>
                )}
              </Await>
            </React.Suspense>
          </tbody>
        </table>
      </div>
    </>
  );
}
